.selected-flag:hover  {
    background-color: transparent !important
}
.selected-flag:focus  {
    background-color: transparent !important
}
.open  {
    background-color: transparent !important
}
.dial-code {
    color: rgba(103, 110, 118, 1) !important
}
.country:hover {
    background-color: rgba(251, 188, 85, 1) !important
}
.highlight {
    background-color: rgba(299,156,40,1) !important
}
.form-control:focus {
    border-color: rgba(299,156,40,1) !important;
    box-shadow: none !important
}