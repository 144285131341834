body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'LatoRegular', 'MonteserratBold', 'MonteserratRegular',
        'PoppinsBold', 'RalewayRegular', 'RalewayRegular', 'InterRegular', 'InterMedium', 'InterBold', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.chakra-ui-light {
    background-color: white;
    /* or your preferred background color */
}

.chakra-ui-dark {
    background-color: black;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

body {
    font-family: system-ui;
    margin: 0;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}
